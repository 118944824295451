import * as debounceSsw from "lodash/debounce";
import React from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import SearchBoxFlyout from "./searchbox-flyout/searchbox.flyout";
import SearchBoxWide from "./searchbox/searchbox.wide";
import SearchBoxIcon from "./searchboxicon/searchboxicon";
import SearchBoxGrid from "./searchboxgrid/searchboxgrid";
import PageViewLogDataId from "../util/pageviewlogdataid/pageviewlogdataid";

global.debounceSsw = debounceSsw;
global.React = React;
global.ReactDOM = ReactDOM;
global.SearchBoxFlyout = SearchBoxFlyout;
global.SearchBoxWide = SearchBoxWide;
global.SearchBoxIcon = SearchBoxIcon;
global.SearchBoxGrid = SearchBoxGrid;
global.ReactDOMServer = ReactDOMServer;
global.PageViewLogDataId = PageViewLogDataId;
