import * as React from "react";
import { decodeHTML } from "../../util/localizationHelper";
import { ModalFnProps } from "../../types/modal-fn";
import CloseIcon from "../../Assets/svg/Close_x_icon";
import "./modal-fn.scss";

export default function ModalFn(props: ModalFnProps): ReturnType<React.FC> {
    const visibiltyClass = props.open ? "visible" : "hidden";
    const overlayClassNames = `ssw-modal-overlay ${props.tenant} ${props.parentName} ${visibiltyClass}`;
    const wrapperTabNavAttributes = { modal: "true", group: "true" };
    const confirmBtnTabNavAttributes = { focusable: true, order: "1" };
    const closeBtnTabNavAttributes = { focusable: true, order: "0" };

    return (
        <div className={overlayClassNames}>
            <div className="ssw-modal" role="dialog" aria-modal="true" {...wrapperTabNavAttributes}>
                <button
                    className="close-btn"
                    aria-label={decodeHTML(props.l10n.close)}
                    onClick={props.onClose}
                    {...closeBtnTabNavAttributes}
                >
                    <CloseIcon />
                </button>
                <p className="ssw-modal-header">{decodeHTML(props.l10n.dataIncomplete)}</p>
                <hr className="ssw-modal-line" />
                <div className="ssw-modal-body">
                    <p className="text">{decodeHTML(props.l10n.enterSearchTerm)}</p>
                    <br />
                </div>
                <button
                    className="confirm-btn"
                    type="submit"
                    aria-label={decodeHTML(props.l10n.ok)}
                    onClick={props.onClose}
                    {...confirmBtnTabNavAttributes}
                >
                    {decodeHTML(props.l10n.ok)}
                </button>
            </div>
        </div>
    );
}
