export function isWebShop(): boolean {
    return (
        typeof window !== "undefined" &&
        (document.documentElement.classList.contains("desktop") ||
            document.documentElement.classList.contains("tablet"))
    );
}

export function isMobileShop(): boolean {
    return (
        typeof window !== "undefined" && document.documentElement.classList.contains("mobileshop")
    );
}

export function isTouchDevice(): boolean {
    return typeof window !== "undefined" && document.documentElement.classList.contains("isTouch");
}

export function deviceType(): DeviceType {
    if (typeof window !== "undefined") {
        if (document.documentElement.classList.contains("isPhone")) return DeviceType.Phone;
        if (document.documentElement.classList.contains("desktop")) return DeviceType.Desktop;
        if (document.documentElement.classList.contains("kiosk")) return DeviceType.Kiosk;
        if (document.documentElement.classList.contains("tablet")) return DeviceType.Tablet;
        return DeviceType.None;
    } else return DeviceType.None;
}

export function mobileOsType(): MobileOsType {
    if (typeof window !== "undefined") {
        const classList = document.documentElement.classList;
        if (classList.contains("isAndroid")) return MobileOsType.Android;
        if (classList.contains("isIOS") || classList.contains("ios")) return MobileOsType.Ios;
        return MobileOsType.None;
    } else return MobileOsType.None;
}

export function customerType(): CustomerType {
    // use Private as default value
    let customerType = CustomerType.Private;

    if (typeof window !== "undefined") {
        const cookieValue = document.cookie
            .split("; ")
            .find((row) => row.startsWith("CustomerType="))
            ?.split("=")[1];

        if (cookieValue === "Company") customerType = CustomerType.Company;
        else if (cookieValue === "Surgery") customerType = CustomerType.Surgery;
    }
    return customerType;
}

export function debugUserType(): void {
    console.log(
        "[SSW-DEBUG]",
        "isWebShop:",
        isWebShop(),
        "| device:",
        deviceType(),
        "| isTouchDevice:",
        isTouchDevice(),
        "| mobileOs:",
        mobileOsType(),
        "| customerType:",
        customerType()
    );
}

export enum CustomerType {
    Company = "Company",
    Private = "Private",
    Surgery = "Surgery"
}

export enum DeviceType {
    Desktop = "desktop",
    Kiosk = "kiosk",
    Phone = "phone",
    Tablet = "tablet",
    None = "none"
}

export enum MobileOsType {
    Android = "android",
    Ios = "ios",
    None = "none"
}
