// GetPageViewLogDataId implemented as Singleton

import { IPageViewLogDataId } from "../../types/pageviewlogdataid";

export default class PageViewLogDataId implements IPageViewLogDataId {
    private static readonly instance: PageViewLogDataId = new PageViewLogDataId();
    private pageViewLogDataId: string | undefined;

    private constructor() {
        // Prevent external instantiation
    }

    public static getInstance(): PageViewLogDataId {
        return PageViewLogDataId.instance;
    }

    public get(): string {
        if (!this.pageViewLogDataId) {
            this.set();
        }
        return this.pageViewLogDataId;
    }

    private set(): void {
        this.pageViewLogDataId = document.documentElement.dataset.pageViewLogDataId;
    }
}
