import React from "react";
import * as debounceSsw from "lodash/debounce";
import { ApplicationShellWrapper } from "@escid-esmkt/applicationshellwrapper";

import { ISearchBoxProps, ISearchBoxFlyoutStates } from "../../types/searchbox";
import { IPageViewLogDataId } from "../../types/pageviewlogdataid";
import PageViewLogDataId from "../../util/pageviewlogdataid/pageviewlogdataid";
import * as Detect from "../../util/detectUserType";
import * as LocaHelper from "../../util/localizationHelper";

import Suggestions from "../suggestions/suggestions";
import SuggestionsWithImagesAndPrices from "../suggestions/suggestionsWithImagesAndPrices";
import Search2021 from "../../Assets/svg/Search_2021_icon";
import "../searchbox/searchbox.scss";

export default class SearchBoxFlyout extends React.Component<
    ISearchBoxProps,
    ISearchBoxFlyoutStates
> {
    private searchBoxRef = React.createRef<HTMLDivElement>();
    private inputRef = React.createRef<HTMLInputElement>();
    private suggestionRef = React.createRef<Suggestions>();
    private suggestionNextGenRef = React.createRef<SuggestionsWithImagesAndPrices>();
    private debouncedFetchSuggestionsFunc: () => void;

    private isWebShop: boolean;
    private isTouchDevice: boolean;
    private device: Detect.DeviceType;
    private customerType: Detect.CustomerType;

    private flyoutIcon: Element;
    private abortController: AbortController | undefined;
    private appShellWrapper: ApplicationShellWrapper;
    readonly pageViewLogDataId: IPageViewLogDataId;

    constructor(props: ISearchBoxProps) {
        super(props);

        this.state = {
            focus: false,
            isOpen: false,
            searchTerm: "",
            suggestions: [],
            articles: [],
            contents: [],
            featurePromotion: "",
            topSearchQueries: [],
            useNewSuggestions: false,
            priceMandantSettings: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleKeyPressed = this.handleKeyPressed.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleFlyoutTriggeredByKeyboardNavigation =
            this.toggleFlyoutTriggeredByKeyboardNavigation.bind(this);
        this.handleOnFocusFallback = this.handleOnFocusFallback.bind(this);
        this.handleOnBlurFallback = this.handleOnBlurFallback.bind(this);

        this.isWebShop = Detect.isWebShop();
        this.isTouchDevice = Detect.isTouchDevice();
        this.device = Detect.deviceType();
        this.customerType = Detect.customerType();

        this.pageViewLogDataId = PageViewLogDataId.getInstance();
        this.appShellWrapper = ApplicationShellWrapper.getInstance();
    }

    public componentDidMount(): void {
        this.setState({
            searchTerm: this.inputRef.current.value || ""
        });

        this.appShellWrapper.subscribeTo(
            "ESMKT.HeadR.FlyoutOpened",
            (payload: string) => {
                if (payload === "SearchBoxFlyout") this.onFlyoutOpened();
            },
            "ESMKT.HeadR.FlyoutOpened"
        );
        this.appShellWrapper.subscribeTo(
            "ESMKT.HeadR.FlyoutClosed",
            (payload: string) => {
                if (payload === "SearchBoxFlyout") this.onFlyoutClosed();
            },
            "ESMKT.HeadR.FlyoutClosed"
        );

        document.addEventListener("mousedown", this.handleClick, false);

        const iconWrapper = document.querySelector(".scope-ssw.mkt-headr-icon-wrapper");
        if (iconWrapper) {
            this.flyoutIcon = iconWrapper.querySelector(".ssw-flyout-icon");
            this.flyoutIcon.addEventListener(
                "keydown",
                this.toggleFlyoutTriggeredByKeyboardNavigation
            );
        }
    }

    public componentDidUpdate(): void {
        this.bindKeyboardNavigation();
    }

    public componentWillUnmount(): void {
        document.removeEventListener("mousedown", this.handleClick, false);
        this.flyoutIcon.removeEventListener(
            "keydown",
            this.toggleFlyoutTriggeredByKeyboardNavigation
        );
    }

    public render() {
        const dontShowSuggestions = !this.areSuggestionsInResponse() || !this.state.searchTerm;
        const priceL10n = LocaHelper.decodePriceL10n(this.props.l10n.prices);
        const productFinderL10n = LocaHelper.decodeProductFinderL10n(this.props.l10n.finders);
        const variantsL10n = LocaHelper.decodeVariantsL10n(this.props.l10n.variants);

        return (
            <div
                className={
                    "searchbox" +
                    ` ${this.props.view}` +
                    ` ${this.state.isOpen ? "open" : ""}` +
                    ` ${this.state.focus ? "focus" : ""}` +
                    ` ${this.props.tenant}` +
                    ` ${this.state.useNewSuggestions ? "" : "legacy"}`
                }
                ref={this.searchBoxRef}
            >
                <div
                    className={
                        this.hasMultipleSuggestionListsForLegacy() ? "search large" : "search"
                    }
                >
                    <form
                        method="get"
                        action={this.props.l10n.searchTarget}
                        acceptCharset="utf-8"
                        onSubmit={this.handleSubmit}
                    >
                        <input
                            ref={this.inputRef}
                            className="ssw-input"
                            type="text"
                            name="query"
                            aria-label={LocaHelper.decodeHTML(this.props.l10n.defaultTerm)}
                            maxLength={50}
                            value={this.state.searchTerm ? this.state.searchTerm : ""}
                            onChange={this.handleChange}
                            onFocus={this.handleOnFocusFallback}
                            onBlur={this.handleOnBlurFallback}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                        />
                        <button
                            className="ssw-button"
                            type="submit"
                            aria-label={LocaHelper.decodeHTML(this.props.l10n.defaultTerm)}
                        >
                            <Search2021 />
                        </button>
                    </form>
                </div>
                {!dontShowSuggestions && (
                    <>
                        <div className={"ssw-backdrop"} />
                        {this.state.useNewSuggestions ? (
                            <div
                                id={`new-suggestions-${this.props.view}`}
                                className={"new-suggestions"}
                            >
                                <SuggestionsWithImagesAndPrices
                                    trackingEndpoint={this.props.trackingEndpoint}
                                    portal={this.props.portal}
                                    culture={this.props.culture}
                                    ref={this.suggestionNextGenRef}
                                    isWide={false}
                                    view={this.props.view}
                                    searchTerm={this.state.searchTerm}
                                    articles={this.state.articles}
                                    contents={this.state.contents}
                                    featurePromotion={this.state.featurePromotion}
                                    topSearchQueries={this.state.topSearchQueries}
                                    priceMandantSettings={this.state.priceMandantSettings}
                                    customerType={this.customerType}
                                    priceL10n={priceL10n}
                                    seeAllResults={LocaHelper.decodeHTML(
                                        this.props.l10n.seeAllResults
                                    )}
                                    imageMainView={LocaHelper.decodeHTML(
                                        this.props.l10n.imageMainView
                                    )}
                                    articlesSuggestHeadline={LocaHelper.decodeHTML(
                                        this.props.l10n.articlesSuggestHeadline
                                    )}
                                    contentSuggestHeadline={LocaHelper.decodeHTML(
                                        this.props.l10n.contentSuggestHeadline
                                    )}
                                    topSearchQueriesHeadline={LocaHelper.decodeHTML(
                                        this.props.l10n.topSearchQueriesHeadline
                                    )}
                                    productFinderL10n={productFinderL10n}
                                    variantsL10n={variantsL10n}
                                    searchTarget={this.props.l10n.searchTarget}
                                />
                            </div>
                        ) : (
                            <div
                                className={
                                    this.hasMultipleSuggestionListsForLegacy()
                                        ? "suggestions multiple-lists"
                                        : "suggestions"
                                }
                            >
                                <Suggestions
                                    trackingEndpoint={this.props.trackingEndpoint}
                                    portal={this.props.portal}
                                    culture={this.props.culture}
                                    ref={this.suggestionRef}
                                    suggestions={this.state.suggestions}
                                    searchTerm={this.state.searchTerm}
                                    showMultipleSuggestionLists={this.hasMultipleSuggestionListsForLegacy()}
                                    articlesSuggestHeadline={LocaHelper.decodeHTML(
                                        this.props.l10n.articlesSuggestHeadline
                                    )}
                                    contentSuggestHeadline={LocaHelper.decodeHTML(
                                        this.props.l10n.contentSuggestHeadline
                                    )}
                                    featurePromotion={this.state.featurePromotion}
                                    topSearchQueries={this.state.topSearchQueries}
                                    topSearchQueriesHeadline={LocaHelper.decodeHTML(
                                        this.props.l10n.topSearchQueriesHeadline
                                    )}
                                    productFinderL10n={productFinderL10n}
                                    searchTarget={this.props.l10n.searchTarget}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }

    private isDesktop = () => this.device === Detect.DeviceType.Desktop;

    private onFlyoutOpened(): void {
        this.setState({ isOpen: true });
        this.inputRef.current.toggleAttribute("focusable");

        if (this.isDesktop()) {
            this.setState({ focus: true });
            this.inputRef.current.focus();
        } else if (this.isTouchDevice) {
            // touch devices may have glitches with virtual keyboard, page scrolling depth
            // MobileOsType.Ios: input cursor and keyboard are not visible, unless the input is touched
            this.setState({ focus: true });
            this.inputRef.current.focus();
        }
    }

    private onFlyoutClosed(): void {
        this.setState({ isOpen: false });
        this.inputRef.current.toggleAttribute("focusable");

        if (this.isDesktop()) {
            this.setState({ focus: false });
        } else if (this.isTouchDevice) {
            // touch devices may have glitches with virtual keyboard, page scrolling depth
            this.setState({ focus: false });
        }
    }

    private handleOnFocusFallback(event: React.FocusEvent<HTMLInputElement>): void {
        const refocusAfterLostFocus =
            event.target?.classList.contains("ssw-input") && !event.relatedTarget;
        setTimeout(() => {
            if (!this.state.focus && refocusAfterLostFocus) {
                // will only execute if the focus was lost to an unexpected action
                this.setState({ focus: true });
            }
        }, 500);
    }

    private handleOnBlurFallback(): void {
        setTimeout(() => {
            if (this.state.focus) {
                // will only execute when the input lost focus, but the flyout was not hidden
                this.setState({ focus: false });
            }
        }, 500);
    }

    private handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        if (!event || !event.target) {
            this.resetSuggestionState();
            return;
        }

        const validSearchTerm =
            event.target.value.length > 50 ? event.target.value.slice(0, 50) : event.target.value;
        this.setState({ searchTerm: validSearchTerm });
        event.target.value ? this.debouncedFetchSuggestions(event) : this.resetSuggestionState();
    }

    private debouncedFetchSuggestions(event: React.ChangeEvent<HTMLInputElement>): void {
        event.persist();

        if (!this.debouncedFetchSuggestionsFunc)
            this.debouncedFetchSuggestionsFunc = debounceSsw(() => {
                this.fetchSuggestion(event);
            }, 250);

        this.debouncedFetchSuggestionsFunc();
    }

    private fetchSuggestion(event: React.ChangeEvent<HTMLInputElement>): void {
        if (!event || !event.target || !event.target.value || !event.target.value.trim().length) {
            this.resetSuggestionState();
            return;
        }

        const searchTerm: string = event.target.value;
        if (searchTerm.length > 50) return;

        const topSearchQueriesLimit: number = this.isWebShop ? 10 : 5;

        const headers = this.pageViewLogDataId.get()
            ? { "X-PageView-ID": this.pageViewLogDataId.get() }
            : {};

        if (this.abortController) {
            this.abortController.abort();
        }

        this.abortController = new AbortController();
        const signal = this.abortController.signal;

        fetch(
            `${this.props.apiUrl}suggestion/?searchTerm=${encodeURIComponent(
                searchTerm
            )}&topSearchQueriesLimit=${topSearchQueriesLimit}`,
            { headers, signal }
        )
            .then((response) => response.json())
            .then(
                (response) => {
                    if (response) {
                        // check for legacy or new suggestion type
                        if (response.clientSuggestionsList) {
                            // legacy suggestion type
                            response.clientSuggestionsList.length ||
                            response.topSearchQueries.length
                                ? this.setState({
                                      useNewSuggestions: false,
                                      suggestions: response.clientSuggestionsList,
                                      featurePromotion: response.featurePromotion,
                                      topSearchQueries: response.topSearchQueries
                                  })
                                : this.resetSuggestionState();
                        } else {
                            // new suggestion type
                            response.articles.length ||
                            response.contents.length ||
                            response.topSearchQueries.length
                                ? this.setState({
                                      useNewSuggestions: true,
                                      articles: response.articles,
                                      contents: response.contents,
                                      featurePromotion: response.featurePromotion,
                                      topSearchQueries: response.topSearchQueries,
                                      priceMandantSettings: response.priceMandantSettings
                                  })
                                : this.resetSuggestionState();
                        }
                    } else this.resetSuggestionState();
                },
                () => {
                    this.resetSuggestionState();
                }
            )
            .then(() => (this.abortController = undefined));
    }

    private areSuggestionsInResponse(): boolean {
        if (this.state.useNewSuggestions) {
            return (
                this.state.articles.length > 0 ||
                this.state.contents.length > 0 ||
                this.state.topSearchQueries.length > 0
            );
        } else {
            return (
                this.state.suggestions.some((list) => list.clientSuggestion.length > 0) ||
                this.state.topSearchQueries.length > 0
            );
        }
    }

    private hasMultipleSuggestionListsForLegacy(): boolean {
        if (this.state.useNewSuggestions) return false;
        return (
            this.state.suggestions.some((list) => list.clientSuggestion.length > 0) &&
            this.state.topSearchQueries.length > 0
        );
    }

    private resetSuggestionState(): void {
        this.setState((state: ISearchBoxFlyoutStates) => ({
            ...state,
            suggestions: [],
            featurePromotion: "",
            topSearchQueries: [],
            articles: [],
            contents: [],
            priceMandantSettings: null
        }));
    }

    private handleSubmit(event: React.FormEvent<HTMLFormElement> | KeyboardEvent): void {
        event.preventDefault();
        if (this.state.searchTerm.trim()) {
            window.location.href = `${this.props.l10n.searchTarget}?query=${encodeURIComponent(
                this.state.searchTerm
            )}`;
        }
    }

    private handleClick(event: Event) {
        const target = event.target as HTMLElement;

        // exclude clicks on searchbox and on mkt header
        const removeFocus =
            this.state.focus &&
            !this.searchBoxRef.current.contains(target) &&
            !target.closest(".mkt-headr-content");

        if (removeFocus) {
            this.setState({ focus: false });
        }
    }

    private toggleFlyoutTriggeredByKeyboardNavigation(event: KeyboardEvent): void {
        if (event.key === "Enter") {
            this.appShellWrapper.publishTo("ESMKT.HeadR.ToggleFlyout", "SearchBoxFlyout");
        } else if (event.shiftKey && event.key === "Tab") {
            this.closeFlyoutTriggeredByKeyboardNavigation();
        }
    }

    private closeFlyoutTriggeredByKeyboardNavigation(): void {
        this.appShellWrapper.publishTo("ESMKT.HeadR.CloseFlyout", null);
    }

    private bindKeyboardNavigation(): void {
        this.state.focus
            ? this.searchBoxRef.current.addEventListener("keydown", this.handleKeyPressed)
            : this.searchBoxRef.current.removeEventListener("keydown", this.handleKeyPressed);
    }

    private handleKeyPressed(event: KeyboardEvent): void {
        if (event.key === "Tab" || (event.shiftKey && event.key === "Tab")) {
            this.closeFlyoutTriggeredByKeyboardNavigation();
            return;
        }

        if (!this.isDesktop()) return;

        if (this.state.useNewSuggestions) {
            switch (event.key) {
                case "Down":
                case "ArrowDown":
                    event.preventDefault();
                    if (this.suggestionNextGenRef.current != null)
                        this.suggestionNextGenRef.current.selectNextSuggestionEntry(false);
                    break;

                case "Up":
                case "ArrowUp":
                    event.preventDefault();
                    if (this.suggestionNextGenRef.current != null)
                        this.suggestionNextGenRef.current.selectNextSuggestionEntry(true);
                    break;

                case "Enter":
                    if (this.suggestionNextGenRef.current != null) {
                        event.preventDefault();
                        const executedRedirect =
                            this.suggestionNextGenRef.current.triggerSuggestionRedirect();
                        if (!executedRedirect) this.handleSubmit(event);
                    } else this.handleSubmit(event);
                    break;

                case "Esc":
                case "Escape":
                    if (this.suggestionNextGenRef.current != null) {
                        window.shell.tabNav.focusPrevious();
                        this.closeFlyoutTriggeredByKeyboardNavigation();
                    }
                    break;

                default:
                    return;
            }
        } else {
            switch (event.key) {
                case "Down":
                case "ArrowDown":
                    event.preventDefault();
                    if (this.suggestionRef.current != null)
                        this.suggestionRef.current.selectNextSuggestionEntry(false);
                    break;

                case "Up":
                case "ArrowUp":
                    event.preventDefault();
                    if (this.suggestionRef.current != null)
                        this.suggestionRef.current.selectNextSuggestionEntry(true);
                    break;

                case "Enter":
                    if (this.suggestionRef.current != null) {
                        event.preventDefault();
                        const executedRedirect =
                            this.suggestionRef.current.triggerSuggestionRedirect();
                        if (!executedRedirect) this.handleSubmit(event);
                    } else this.handleSubmit(event);
                    break;

                case "Esc":
                case "Escape":
                    if (this.suggestionRef.current != null) {
                        window.shell.tabNav.focusPrevious();
                        this.closeFlyoutTriggeredByKeyboardNavigation();
                    }
                    break;

                default:
                    return;
            }
        }
    }
}
